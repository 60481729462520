import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {useFormData} from "../context/FormDataContext";

const Logo = ({data}) => {
    if (data) {
        return <div className="text-center m-3"><img src={data} alt="Logo" style={{maxHeight: "4em", maxWidth: "20em"}}/></div>
    }

    return <></>
}

const SubmittedPage = () => {
    let serverData = useFormData();

    return (
        <div style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
            <div className="text-center"><img src={serverData.company.logo} style={{width: "4em"}} alt="Logo"/></div>
            <div className="text-center m-3 text-primary"><FontAwesomeIcon icon={faCheck} size="10x"/></div>
            <h2 className="text-center">Obrigado!</h2>

            <div className="lead m-5" dangerouslySetInnerHTML={{__html: serverData.company.final}}/>


            <Logo data={serverData.logos?.client} />
            <Logo data={serverData.logos?.financing} />
        </div>
    )
}
export default SubmittedPage