import {useFormData} from "../../context/FormDataContext";
import {useState} from "react";
import {Button, Card, Form, ProgressBar} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {faSquare, faSquareCheck} from "@fortawesome/free-regular-svg-icons";
import * as PropTypes from "prop-types";
import moment from "moment";
import 'moment/locale/pt';


let ShowAmericana = ({question, currentAnswer, onChange}) => {
    let AmericanaAnswer = ({question, answer, onChange, check}) => {
        let notify = () => {
            onChange({
                questionId: question.id,
                value: answer.id
            })
        }

        return (
            <Button
                variant={check ? "primary" : "outline-primary"}
                label={answer.text}
                name={question.id}
                type="radio"
                id="inline-radio-1"
                style={{display: "block", margin: "0.3rem", textAlign: "left"}}
                checked={check}
                onClick={() => {notify()}}
            ><FontAwesomeIcon size="lg" icon={check ? faSquareCheck : faSquare}/> <span style={{marginLeft: "0.2rem"}}>{answer.text}</span></Button>
        )
    }
    let checks = question.answers.map(it => it.id === currentAnswer)

    let onLocalChange = (data) => {
        onChange({
            ...data,
            moveNext: true
        })
    }

    return (
        <Form.Group controlId="formBasicEmail" className="w-75" style={{maxWidth: "600px"}}>
            <Form.Label>{question.text}</Form.Label>
            <div key={`inline-radio`} className="d-grid">
                {question.answers.map((it, id) => <AmericanaAnswer onChange={onLocalChange}
                                                                   question={question} index={id}
                                                                   answer={it} key={id} check={checks[id]} />)}
            </div>
        </Form.Group>
    )
}

let ShowTextoLivre = ({question, onChange, currentAnswer}) => {
    let [answer, setAnswer] = useState(currentAnswer)

    let notify = (value) => {
        onChange({
            questionId: question.id,
            value: value,
        })
    }

    return (
        <Form.Group controlId="formBasicEmail" className="w-75" style={{maxWidth: "600px"}}>
            <Form.Label>{question.text}</Form.Label>
            <Form.Control as="textarea" rows={3} type="text" placeholder="Resposta" value={answer}
                          onChange={(e) => { setAnswer(e.target.value); notify(e.target.value); }}
            />
        </Form.Group>
    )
}

const QuestionComponent = ({name, fase, question, currentAnswer, onChange}) => {
    let questionByType = {
        "Americana": ShowAmericana,
        "Unknown": ShowAmericana,
        "TextoLivre": ShowTextoLivre,
        "Booleano": ShowAmericana,
    }
    const SpecificQuestion = questionByType[question.questionType]
    if (SpecificQuestion === undefined) throw Error(`No question of type ${question.questionType} implemented`)
    return <>
        <SpecificQuestion question={question} currentAnswer={currentAnswer} onChange={(data) => {
            onChange({
                name: name,
                fase: fase.uid,
                answer: data
            })
        }} />
    </>
}

const questionReferences = (form) => {
    let res = []

    if (!form.data) return []
    form.data.fases.forEach((f, fIndex) => {
        f.questions.forEach((q, qIndex) => {
            res.push({
                name: form.name,
                fase: fIndex,
                question: qIndex
            })
        })
    })

    return res
}

const FormTitle = ({data}) => {
    if (!!data.action) return <ShowActionTitle details={data.action.details} />
    else return <ShowTrainerTitle trainer={data.trainer} />
}

const ShowActionTitle = ({details}) => {
    let beginDateMoment = moment(details.BeginDate).locale("pt")
    let endDateMoment = moment(details.EndDate).locale("pt")

    return (
        <div className="text-center" style={{marginTop: "0.5em"}}>
            <p className="lead" style={{marginBottom: 0}}>
                {details.name}
            </p>
            <div>
                <span className="small" style={{marginBottom: 0}}>{beginDateMoment.format("L")}</span>  - <span className="small">{endDateMoment.format("L")}</span>
            </div>
        </div>
    )
}

const ShowTrainerTitle = ({trainer}) => {
    return (
        <div className="text-center" style={{marginTop: "0.5em"}}>
            <p className="lead" style={{marginBottom: 0}}>
                Formador {trainer.details.Abbreviation}
            </p>
            <div>
                <span className="small" style={{marginBottom: 0}}>{trainer.module.Designation}</span>
            </div>
        </div>
    )
}

FormTitle.propTypes = {isAction: PropTypes.any};
const FormController = ({onSubmit}) => {
    let serverData = useFormData();
    let [response, setResponse] = useState({

    })

    let forms = [
        { name: "action", data: serverData.action?.template },
        { name: "trainer", data: serverData.trainer?.template },
    ]

    let allQuestions = forms.reduce((acc, it) => acc.concat(questionReferences(it)), [])
    let totalQuestions = allQuestions.length

    let [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)

    let submit = () => {
        onSubmit(response)
    }
    let timeout = null
    let onChange = (data) => {
        if (timeout) clearTimeout(timeout)
        let newResponse = { ...response };
        if (!newResponse[data.name]) {
            newResponse[data.name] = {}
        }
        if (!newResponse[data.name][data.fase]) {
            newResponse[data.name][data.fase] = {}
        }

        newResponse[data.name][data.fase][data.answer.questionId] = data.answer.value

        setResponse(newResponse)

        if (!isLastQuestion() && data.answer.moveNext) {
            setTimeout(() => {
                setCurrentQuestionIndex(currentQuestionIndex+1)
            }, 200)
        }

        console.log(response)
    }

    let isFirstQuestion = () => {
        return currentQuestionIndex === 0
    }

    let isLastQuestion = () => {
        return currentQuestionIndex + 1 === totalQuestions
    }

    let getForm = (name) => forms.find(it => it.name === name)?.data

    let questionRef = allQuestions[currentQuestionIndex];
    let currentName = questionRef.name;
    let form = getForm(questionRef.name);
    let currentFase = form.fases[questionRef.fase];
    let currentQuestion = currentFase.questions[questionRef.question];


    let currentAnswerFor = (name, fase, question) => {
        if (!response[name]) return undefined
        if (!response[name][fase.uid]) return undefined
        return response[name][fase.uid][question.id]
    }

    let isQuestionAnswered = () => {
        let currentAnswer = currentAnswerFor(currentName, currentFase, currentQuestion)
        if (currentAnswer === undefined) return false
        if (currentAnswer === null) return false
        return currentAnswer !== "";
    }

    let progress = ((currentQuestionIndex+1) / totalQuestions) * 100

    return (
        <Card className="formCard">
            <div>
                <div className="text-center"><img src={serverData.company.logo} alt="Logo" style={{width: "4em"}} /></div>
                <FormTitle data={serverData} />

                <div className="m-3">
                    <ProgressBar variant="primary" now={progress}  />
                </div>
            </div>
            <Card.Body style={{display: "flex", alignItems: "center", minHeight:"100% 100vh", justifyContent: "center"}}>
                <QuestionComponent
                    name={currentName}
                    fase={currentFase}
                    question={currentQuestion}
                    currentAnswer={currentAnswerFor(currentName, currentFase, currentQuestion)}
                    onChange={onChange} />
            </Card.Body>
            {isLastQuestion() && <div className="d-grid m-3"><Button disabled={!isQuestionAnswered()} onClick={submit}>Submeter</Button></div>}
            <Card.Footer style={{textAlign: "right"}}>
                <div className="btn-group" role="group">
                    {!isFirstQuestion() && <Button variant="outline-secondary" onClick={() => setCurrentQuestionIndex(currentQuestionIndex-1)}><FontAwesomeIcon icon={faChevronUp} /> Anterior</Button>}
                    {!isLastQuestion() && <Button variant="outline-primary" disabled={!isQuestionAnswered()} onClick={() => setCurrentQuestionIndex(currentQuestionIndex+1)}><FontAwesomeIcon icon={faChevronDown} /> Próxima</Button>}
                </div>
            </Card.Footer>
        </Card>
    );
}

export default FormController