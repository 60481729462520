import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

const codeToMessage = (code) => {
    switch (code) {
        case "ALREADY_FILLED":
            return "Já submeteu este formulário"
        case "INVALID":
            return "Pedido inválido"
        case "UNKNOWN":
            return "Erro interno"
        default:
            return code
    }
}

const ErrorPage = ({error}) => {
    return (
        <div style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
            <div className="text-center m-3 text-danger"><FontAwesomeIcon icon={faXmark} size="10x"/></div>
            <h2 className="text-center">{codeToMessage(error.code)}</h2>
            {error.message && <p className="text-center text-secondary">{error.message}</p>}
            {!error.message && <p className="text-center text-secondary">Entre em contacto com a administração</p>}
        </div>
    )
}
export default ErrorPage